import { Canvas } from '@react-three/fiber'
import { OrbitControls, useGLTF } from '@react-three/drei'
import { useLoader } from '@react-three/fiber'
import * as THREE from 'three'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader'

import './App.css';

function App() {
  return (
    <div id="canvas-container">
      <Canvas camera={{fov:20}} scene={{background: new THREE.Color( 0x000000 )}}>
          <Switch />
          <ambientLight />
          <OrbitControls minPolarAngle={Math.PI / 2} maxPolarAngle={Math.PI / 2} />
      </Canvas>
    </div>
  );
}

function Switch(props) {
  const gltf = useLoader(GLTFLoader, '/switch.glb')
  return <primitive object={gltf.scene} />
}

useGLTF.preload('/switch.glb')

export default App;
